import { PageProps } from 'gatsby'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAsync } from 'react-use'
import { getVideo } from '../../apiClients/videos'
import Heading from '../../components/Heading'
import Layout from '../../components/Layout'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarkdownRenderer } from '../../components/MarkdownRenderer'
import Spinner from '../../components/Spinner'
import StoreItemHeader, {
	propsFromVideo,
} from '../../components/StoreItemHeader'
import { useAppContext } from '../../context'
import Breadcrumb from '../../models/Breadcrumb'
import Entity from '../../models/Entity'
import { SettingKey } from '../../models/GlobalSetting'
import Video from '../../models/Video'
import { buildMetaTitle } from '../../utils/meta'

export default function Videos({ params, location }: PageProps) {
	const [video, setVideos] = useState<Entity<Video>>()
	const [loading, setLoading] = useState(true)
	const { getSettingValue } = useAppContext()

	useAsync(async () => {
		setLoading(true)
		const response = await getVideo(params.urlPath)
		if (response) setVideos(response)
		setLoading(false)
	}, [params.urlPath])

	const breadcrumbs: Breadcrumb[] = [
		{ link: '/videos/', featureName: 'videos' },
		{
			link: location.pathname,
			display: video?.attributes.title ?? params.urlPath,
		},
	]

	const metaTitle = getSettingValue(SettingKey.META_TITLE)

	return (
		<Layout breadcrumbs={breadcrumbs}>
			<Helmet>
				<title>{buildMetaTitle([video?.attributes.title, metaTitle])}</title>
			</Helmet>
			<LayoutContainer>
				{loading && !video ? (
					<Spinner />
				) : video ? (
					<>
						<MarkdownRenderer
							header={<StoreItemHeader {...propsFromVideo(video.attributes)} />}
							children={video?.attributes?.markdown ?? '# video not available'}
						/>
					</>
				) : (
					<Heading>Video not found</Heading>
				)}
			</LayoutContainer>
		</Layout>
	)
}
