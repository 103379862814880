import { API_URI } from '.'
import ArrayPayload from '../models/ArrayPayload'
import BlogEntry from '../models/BlogEntry'
import Entity from '../models/Entity'
import Video from '../models/Video'
import { getHeaders } from './auth'

export const getVideos = async (): Promise<ArrayPayload<Video>> => {
	const response = await fetch(
		`${API_URI}/videos` +
			`?populate=logo` +
			`&sort[0]=priority:desc` +
			`&sort[1]=publishedAt:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	return response.json()
}

export const getVideo = async (urlPath: string): Promise<Entity<Video>> => {
	const response = await fetch(
		`${API_URI}/videos?populate=logo&filters[urlPath][$eq]=${urlPath}`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	const json = await response.json()

	return json.data[0]
}
